import React,{useEffect} from 'react'
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function Chat() {
    
  return (
    <div>
         <div className="App">
            {/* <TawkMessengerReact
                propertyId="property_id"
                widgetId="default"/> */}
        </div>
    </div>
  )
}

export default Chat