import Layout1 from './Components/Layout1'
// import Layout2 from './Components/Layout2'
import Chat from './Components/Chat'
function App() {
  return (
    <div className="App">
     <Layout1/>
     <Chat/>
     {/* <Layout2/> */}
    </div>
  );
}

export default App;
